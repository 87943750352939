if (document.querySelector(".block.statistics")) {
    const animationDuration = 1000; // 2 seconds
    const frameDuration = 1000 / 60; // 1 frame per 60ms, 60 FPS
    const totalFrames = Math.round(animationDuration / frameDuration);

    // An ease-out function that slows the count as it progresses
    const easeOutQuad = (t) => t * (2 - t);

    // The animation function, which takes an Element
    const animateCountUp = (el) => {
        let percentage = el.classList.contains("percentage");
        let frame = 0;
        let countTo = parseInt(el.innerHTML, 10);

        if (percentage) countTo = parseFloat(el.innerHTML, 10);

        // Start the animation running 60 times per second
        const counter = setInterval(() => {
            frame++;
            // Calculate our progress as a value between 0 and 1
            // Pass that value to our easing function to get our
            // progress on a curve
            const progress = easeOutQuad(frame / totalFrames);
            // Use the progress value to calculate the current count

            let currentCount = Math.round(countTo * progress);

            if (percentage) {
                currentCount = Math.round(countTo * progress * 100) / 100;
            }

            // If the current count has changed, update the element
            if (percentage) {
                if (parseFloat(el.innerHTML, 10) !== currentCount) {
                    el.innerHTML = currentCount;
                }
            } else {
                if (parseInt(el.innerHTML, 10) !== currentCount) {
                    el.innerHTML = currentCount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Add . seperator every 3 numbers.
                }
            }

            // If we’ve reached our last frame, stop the animation
            if (frame === totalFrames) {
                clearInterval(counter);
            }
        }, frameDuration);
    };

    // Run the animation on all elements with a class of ‘countup’
    const runAnimations = () => {
        const countupEls = document.querySelectorAll(
            ".block.statistics .countup"
        );
        countupEls.forEach(animateCountUp);
    };

    // Start animation when element is in view
    document.addEventListener("scroll", () => {
        {
            let block = document.querySelector(".block.statistics");

            if (isInViewport(block)) {
                if (!block.classList.contains("counted-up")) {
                    runAnimations();
                }
                block.classList.add("counted-up");
            }
        }
    });
}

// Checks if element is in viewport
function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
}
